.scanner__title {
    text-align: center;
    font-size: 1.125rem;
}

.scanner__message {
    text-align: center;
}

.scanner__scanner {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
