.bottomNav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    background-color: #333;
    align-items: flex-end;
    z-index: 100;
}

.bottomNav__link {
    color: #fff;
    padding: 0.75rem;
    flex-grow: 1;
    text-decoration: none;
    font-size: 0.75rem;
    text-align: center;
    padding-top: 0rem;
    padding-bottom: 0.125rem;
    user-select: none;
    outline: none;
}

.bottomNav__link svg,
.bottomNav__link img {
    width: 35px;
    height: 35px;
    display: block;
    margin: 0 auto;
    position: relative;
}

.bottomNav__link svg g,
.bottomNav__link svg path {
    fill: #fff;
}

.bottomNav__link:hover, .bottomNav__link:focus {
    color: #4cc33f;
}

.bottomNav__link:hover svg, .bottomNav__link:focus svg {
    fill: #4cc33f;
}

.bottomNav__link:hover svg g,
.bottomNav__link:focus svg g,
.bottomNav__link:hover svg path,
.bottomNav__link:focus svg path {
    fill: inherit;
}

.bottomNav__link.active {
    color: #4cc33f;
}

.bottomNav__link.active svg {
    fill: #4cc33f;
}

.bottomNav__link.active svg g,
.bottomNav__link.active svg path {
    fill: inherit;
}